function setWindowHeight() {
    // First we get the viewport height, and we multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

function debounce(func, wait) {
    let timeout = null
    return () => {
        const later = () => {
            timeout = null;
            func()
        }
        if (timeout !== null) {
            clearTimeout(timeout)
        }
        timeout = window.setTimeout(later, wait)
    }
}

window.addEventListener('load', setWindowHeight)
window.addEventListener('resize', debounce(setWindowHeight, 200), {passive: true})